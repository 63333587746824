import React from 'react';

const UxUiUserCentered = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.7279 45.7544H19.0501C18.8517 45.7544 18.6614 45.6757 18.5211 45.5354C18.3808 45.3951 18.302 45.2047 18.302 45.0063C18.302 44.8078 18.3808 44.6176 18.5211 44.4773C18.6614 44.337 18.8517 44.2581 19.0501 44.2581H39.8177C40.1871 44.2581 40.5528 44.1855 40.8941 44.0441C41.2354 43.9027 41.5455 43.6954 41.8067 43.4342C42.0679 43.173 42.2751 42.863 42.4164 42.5218C42.5578 42.1805 42.6306 41.8146 42.6306 41.4452V4.30912C42.5878 3.57704 42.2777 2.88616 41.7592 2.36762C41.2406 1.84907 40.5498 1.53897 39.8177 1.49622H15.8781C15.1408 1.53185 14.4426 1.8387 13.9178 2.35794C13.3931 2.87718 13.0787 3.57217 13.0353 4.30912V10.0546C13.0353 10.253 12.9565 10.4432 12.8162 10.5835C12.6759 10.7238 12.4856 10.8027 12.2872 10.8027C12.0888 10.8027 11.8985 10.7238 11.7582 10.5835C11.6179 10.4432 11.5391 10.253 11.5391 10.0546V4.30912C11.547 3.16353 12.0076 2.06751 12.8205 1.26024C13.6334 0.452977 14.7325 -2.72433e-05 15.8781 1.22915e-09H39.8177C40.9605 1.22881e-09 42.0565 0.453955 42.8646 1.26207C43.6728 2.07019 44.1268 3.16627 44.1268 4.30912V41.4452C44.131 42.0199 44.0195 42.5896 43.7988 43.1203C43.5781 43.6509 43.2527 44.1316 42.8422 44.5338C42.4316 44.9359 41.9443 45.2513 41.4092 45.461C40.8742 45.6708 40.3024 45.7705 39.7279 45.7544Z"
      fill="#475467"
    />
    <path
      d="M43.2889 7.4806H12.2872C12.0888 7.4806 11.8985 7.40172 11.7582 7.26142C11.6179 7.12113 11.5391 6.9309 11.5391 6.73249C11.5391 6.53407 11.6179 6.34385 11.7582 6.20355C11.8985 6.06325 12.0888 5.98438 12.2872 5.98438H43.2889C43.4873 5.98438 43.6776 6.06325 43.8179 6.20355C43.9582 6.34385 44.037 6.53407 44.037 6.73249C44.037 6.9309 43.9582 7.12113 43.8179 7.26142C43.6776 7.40172 43.4873 7.4806 43.2889 7.4806Z"
      fill="#475467"
    />
    <path
      d="M43.2873 39.7658H19.3477C19.1493 39.7658 18.959 39.6871 18.8187 39.5468C18.6784 39.4065 18.5996 39.2161 18.5996 39.0176C18.5996 38.8192 18.6784 38.629 18.8187 38.4887C18.959 38.3484 19.1493 38.2695 19.3477 38.2695H43.2873C43.4857 38.2695 43.6759 38.3484 43.8162 38.4887C43.9565 38.629 44.0354 38.8192 44.0354 39.0176C44.0354 39.2161 43.9565 39.4065 43.8162 39.5468C43.6759 39.6871 43.4857 39.7658 43.2873 39.7658Z"
      fill="#475467"
    />
    <path
      d="M29.5579 42.7345H26.0567C25.8583 42.7345 25.6681 42.6558 25.5278 42.5155C25.3875 42.3752 25.3086 42.1848 25.3086 41.9864C25.3086 41.788 25.3875 41.5978 25.5278 41.4575C25.6681 41.3172 25.8583 41.2383 26.0567 41.2383H29.5579C29.7563 41.2383 29.9465 41.3172 30.0868 41.4575C30.2271 41.5978 30.306 41.788 30.306 41.9864C30.306 42.1848 30.2271 42.3752 30.0868 42.5155C29.9465 42.6558 29.7563 42.7345 29.5579 42.7345Z"
      fill="#475467"
    />
    <path
      d="M12.2888 21.9891C11.2542 22.1799 10.186 22.0471 9.22964 21.6089C8.27323 21.1706 7.47512 20.4482 6.94403 19.5401C6.41294 18.6319 6.17468 17.5822 6.26167 16.5337C6.34866 15.4853 6.75664 14.4892 7.43013 13.681C8.10363 12.8728 9.00986 12.2919 10.0254 12.0173C11.041 11.7427 12.1165 11.7876 13.1055 12.1463C14.0945 12.5049 14.949 13.1596 15.5525 14.0213C16.156 14.883 16.4794 15.9098 16.4783 16.9619C16.5785 18.1817 16.1955 19.3923 15.412 20.3326C14.6284 21.2729 13.5068 21.8677 12.2888 21.9891ZM12.2888 13.4308C10.8225 13.4308 9.62561 15.0168 9.62561 16.9619C9.62561 18.9069 10.8225 20.4929 12.2888 20.4929C13.0692 20.288 13.7597 19.8304 14.2524 19.1916C14.7452 18.5527 15.0124 17.7687 15.0124 16.9619C15.0124 16.1551 14.7452 15.371 14.2524 14.7321C13.7597 14.0933 13.0692 13.6357 12.2888 13.4308Z"
      fill="#475467"
    />
    <path
      d="M16.4493 37.3142C16.2956 37.3142 16.1455 37.2688 16.0177 37.1836C15.8899 37.0984 15.7902 36.9773 15.7311 36.8355C15.6696 36.6524 15.6783 36.4529 15.7558 36.2759C15.8332 36.0989 15.9737 35.957 16.15 35.8779C17.0278 35.5492 17.7846 34.9607 18.3193 34.1908C18.8539 33.4209 19.1411 32.5061 19.1424 31.5688V28.9654C19.1464 28.3551 19.0292 27.7502 18.7975 27.1857C18.5658 26.6211 18.2243 26.1082 17.7928 25.6767C17.3613 25.2452 16.8484 24.9038 16.2839 24.6721C15.7193 24.4404 15.1144 24.3231 14.5042 24.3271H10.0753C9.46512 24.3231 8.86019 24.4404 8.29565 24.6721C7.73111 24.9038 7.21823 25.2452 6.78674 25.6767C6.35524 26.1082 6.0137 26.6211 5.78201 27.1857C5.55032 27.7502 5.43311 28.3551 5.43709 28.9654V31.4791C5.43844 32.4164 5.7256 33.331 6.26026 34.1009C6.79493 34.8709 7.55168 35.4595 8.42953 35.7882C8.60579 35.8673 8.74624 36.009 8.82367 36.186C8.90111 36.363 8.90995 36.5625 8.84842 36.7456C8.81224 36.8389 8.75776 36.924 8.68815 36.9959C8.61854 37.0677 8.5352 37.1246 8.44314 37.1637C8.35107 37.2028 8.25219 37.2231 8.15218 37.2232C8.05218 37.2234 7.95314 37.2034 7.86096 37.1646C6.71138 36.7282 5.72413 35.9484 5.03344 34.9311C4.34275 33.9138 3.98213 32.7086 4.00068 31.4791V28.9654C4.03866 27.39 4.68136 25.8898 5.79562 24.7755C6.90987 23.6612 8.41023 23.0184 9.98557 22.9805H14.2647C15.8661 22.98 17.4042 23.6057 18.5505 24.724C19.6967 25.8423 20.3602 27.3645 20.3993 28.9654V31.4791C20.4179 32.7086 20.0572 33.9138 19.3665 34.9311C18.6758 35.9484 17.6886 36.7282 16.539 37.1646C16.5192 37.2199 16.4887 37.2707 16.4493 37.3142Z"
      fill="#475467"
    />
    <path
      d="M12.2872 47.1878C12.0911 47.1804 11.9052 47.0992 11.7665 46.9604C11.6277 46.8217 11.5465 46.6358 11.5391 46.4397V36.5645C11.5391 36.3661 11.6179 36.1759 11.7582 36.0356C11.8985 35.8953 12.0888 35.8164 12.2872 35.8164C12.4856 35.8164 12.6759 35.8953 12.8162 36.0356C12.9565 36.1759 13.0353 36.3661 13.0353 36.5645V46.4397C13.0353 46.6381 12.9565 46.8284 12.8162 46.9687C12.6759 47.109 12.4856 47.1878 12.2872 47.1878Z"
      fill="#475467"
    />
    <path
      d="M14.1777 47.9969C13.4996 48.0168 12.8349 47.8058 12.2925 47.3983C11.6052 47.8349 10.7935 48.0334 9.98239 47.9631C9.17124 47.8928 8.40568 47.5578 7.80381 47.0095C7.65195 46.8591 7.56591 46.6546 7.56445 46.4409V28.2169C7.56445 28.0184 7.64324 27.8282 7.78353 27.6879C7.92383 27.5476 8.11415 27.4688 8.31256 27.4688C8.51097 27.4688 8.7012 27.5476 8.8415 27.6879C8.9818 27.8282 9.06067 28.0184 9.06067 28.2169V46.1715C9.4552 46.4818 9.94922 46.6378 10.4504 46.6102C10.9516 46.5827 11.4257 46.3736 11.7838 46.0219C11.9271 45.8916 12.1138 45.8192 12.3074 45.8192C12.5011 45.8192 12.6879 45.8916 12.8312 46.0219C13.1893 46.3736 13.6633 46.5827 14.1645 46.6102C14.6657 46.6378 15.1597 46.4818 15.5542 46.1715V28.2169C15.5542 28.0184 15.6331 27.8282 15.7734 27.6879C15.9137 27.5476 16.1039 27.4688 16.3023 27.4688C16.5007 27.4688 16.6911 27.5476 16.8314 27.6879C16.9717 27.8282 17.0504 28.0184 17.0504 28.2169V46.4409C17.049 46.6546 16.9629 46.8591 16.8111 47.0095C16.0673 47.6219 15.1408 47.9692 14.1777 47.9969Z"
      fill="#475467"
    />
  </svg>
);

export default UxUiUserCentered;
